import config from '@/config/global'
import dictionary from '@/config/dictionary'
import { countriesOptions } from '@/utils/countries'
import { SOLE_TRADER_COMPANY_NUMBER } from '@/utils/constants'

export default {
  name: 'companyDescriptionForm',
  routeName: 'company-details',
  formTitle: 'Company Details',
  navTitle: 'Company Details',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'companyName',
            veeAs: 'company name',
            label: 'What is your company name?',
            placeholder: '',
            rules: 'required',
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'companyNumber',
            veeAs: dictionary.companyNumber,
            label: `What is your ${dictionary.companyNumber}?`,
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'companyNumber',
                rule(selected) {
                  return selected !== SOLE_TRADER_COMPANY_NUMBER
                }
              }
            ],
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'companyStructures',
            veeAs: 'type of business',
            label: 'What type of business are you?',
            placeholder: '',
            rules: 'required',
            columns: 3,
            options: [
              {
                label: 'Private Limited Company',
                value: 'Private Limited Company'
              },
              {
                label: 'LLP',
                value: 'LLP'
              },
              {
                label: 'Unlimited Company (Sole Trader)',
                value: 'Unlimited Company (Sole Trader)'
              },
              {
                label: 'PLC',
                value: 'PLC'
              },
              {
                label: 'Other',
                value: 'Other'
              }
            ],
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'datepicker-dropdown',
            type: '',
            isInline: false,
            name: 'incorporationDate',
            veeAs: 'incorporation date',
            label: 'Incorporation Date',
            placeholder: 'DD-MM-YYYY',
            labelSoleTrader: 'Date your Business was established',
            icon: {
              name: 'today',
              position: 'right'
            },
            rules: 'required',
            selected: ''
          },
          {
            field: 'dummy'
          }
        ]
      ]
    },
    {
      title: 'Business Address',
      fields: [
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'registeredAddressLine',
            veeAs: 'registered address',
            label: 'Registered Address',
            placeholder: '',
            rules: 'required',
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'registeredAddressCity',
            veeAs: 'registered address city',
            label: 'City / Town',
            placeholder: '',
            rules: 'required',
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'registeredAddressCountry',
            veeAs: 'registered address country',
            label: 'Country',
            placeholder: '',
            rules: 'required',
            options: countriesOptions,
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            ...(config.whitelabel.forms.states && {
              choice: 'single',
              field: 'select',
              type: '',
              name: 'registeredAddressStatecode',
              veeAs: 'registered address state',
              label: 'State',
              placeholder: '',
              rules: 'required',
              selected: '',
              options: config.whitelabel.forms.stateOptions
            })
          },
          {
            ...(config.whitelabel.forms.provinces && {
              choice: 'single',
              field: 'select',
              type: '',
              name: 'registeredAddressStatecode',
              veeAs: 'registered address state',
              label: 'Province',
              placeholder: '',
              rules: 'required',
              selected: '',
              options: config.whitelabel.forms.provinceOptions
            })
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'registeredAddressPostcode',
            veeAs: 'registered address postcode',
            label: 'Postcode',
            placeholder: '',
            rules: 'required',
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'radio',
            isInline: true,
            name: 'hasDifferentTradingAddress',
            veeAs: ' ',
            label: 'Do you use a different trading address?',
            placeholder: '',
            rules: 'required',
            options: [
              {
                label: 'Yes',
                value: true
              },
              {
                label: 'No',
                value: false
              }
            ],
            selected: ''
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'tradingAddressLine',
            veeAs: 'trading address',
            label: 'Trading Address',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'hasDifferentTradingAddress',
                values: [true]
              }
            ],
            selected: ''
          },
          {
            field: 'dummy',
            dependsOn: [
              {
                fieldName: 'hasDifferentTradingAddress',
                values: [true]
              }
            ]
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'tradingAddressCity',
            veeAs: 'town',
            label: 'City / Town',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'hasDifferentTradingAddress',
                values: [true]
              }
            ],
            selected: ''
          },
          {
            field: 'dummy',
            dependsOn: [
              {
                fieldName: 'hasDifferentTradingAddress',
                values: [true]
              }
            ]
          }
        ],
        [
          {
            choice: 'single',
            field: 'select',
            type: '',
            name: 'tradingAddressCountry',
            veeAs: 'trading address',
            label: 'Country',
            placeholder: '',
            rules: 'required',
            options: countriesOptions,
            dependsOn: [
              {
                fieldName: 'hasDifferentTradingAddress',
                values: [true]
              }
            ],
            selected: ''
          },
          {
            field: 'dummy',
            dependsOn: [
              {
                fieldName: 'hasDifferentTradingAddress',
                values: [true]
              }
            ]
          }
        ],
        [
          {
            ...(config.whitelabel.forms.states && {
              choice: 'single',
              field: 'select',
              type: '',
              name: 'tradingAddressState',
              veeAs: 'trading address state',
              label: 'State',
              placeholder: '',
              rules: 'required',
              selected: '',
              options: config.whitelabel.forms.stateOptions,
              dependsOn: [
                {
                  fieldName: 'hasDifferentTradingAddress',
                  values: [true]
                }
              ]
            })
          },
          {
            ...(config.whitelabel.forms.provinces && {
              choice: 'single',
              field: 'select',
              type: '',
              name: 'tradingAddressState',
              veeAs: 'trading address state',
              label: 'Province',
              placeholder: '',
              rules: 'required',
              selected: '',
              options: config.whitelabel.forms.provinceOptions,
              dependsOn: [
                {
                  fieldName: 'hasDifferentTradingAddress',
                  values: [true]
                }
              ]
            })
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'single',
            field: 'input',
            type: 'text',
            name: 'tradingAddressPostCode',
            veeAs: 'trading postcode',
            label: 'Postcode',
            placeholder: '',
            rules: 'required',
            dependsOn: [
              {
                fieldName: 'hasDifferentTradingAddress',
                values: [true]
              }
            ],
            selected: ''
          },
          {
            field: 'dummy',
            dependsOn: [
              {
                fieldName: 'hasDifferentTradingAddress',
                values: [true]
              }
            ]
          }
        ]
      ]
    },
    {
      title: 'Business Description',
      fields: [
        [
          {
            choice: 'multiple',
            field: 'input',
            type: 'checkbox',
            name: 'businessModel',
            veeAs: 'type of asset',
            label: 'What describes your business model best?',
            placeholder: '',
            columns: 3,
            rules: 'required',
            selected: '',
            options: [
              {
                label: 'B2C',
                value: 'B2C'
              },
              {
                label: 'B2B',
                value: 'B2B'
              },
              {
                label: 'B2B2C',
                value: 'B2B2C'
              },
              {
                label: 'Social Enterprise',
                value: 'socialEnterprise'
              }
            ]
          },
          {
            field: 'dummy'
          }
        ],
        [
          {
            choice: 'multiple',
            field: 'input',
            type: 'checkbox',
            name: 'detailedSectorsClassification',
            veeAs: 'sector',
            label: 'Which sector(s) do you operate in?',
            placeholder: '',
            rules: 'required',
            columns: 2,
            selected: [],
            isGenerateSelectedOption: false,
            options: [
              { label: 'Aerospace', value: 'Aerospace' },
              { label: 'Age care services', value: 'Age care services' },
              { label: 'Age care tech', value: 'Age care tech' },
              { label: 'Agriculture', value: 'Agriculture' },
              { label: 'Agritech', value: 'Agritech' },
              { label: 'Automotive', value: 'Automotive' },
              { label: 'Autonomous Vehicles', value: 'Autonomous Vehicles' },
              { label: 'Artificial Intelligence', value: 'Artificial Intelligence' },
              { label: 'Banking', value: 'Banking' },
              { label: 'Biotech', value: 'Biotech' },
              { label: 'Bots', value: 'Bots' },
              { label: 'Building society', value: 'Building society' },
              { label: 'Business Services', value: 'Business Services' },
              { label: 'Buying and selling of own real estate', value: 'Buying and selling of own real estate' },
              { label: 'Cannabis', value: 'Cannabis' },
              { label: 'Construction', value: 'Construction' },
              { label: 'Courier', value: 'Courier' },
              { label: 'Creative', value: 'Creative' },
              { label: 'Cybersecurity', value: 'Cybersecurity' },
              { label: 'Data Analytics/Data Science', value: 'Data Analytics/Data Science' },
              { label: 'Defense', value: 'Defense' },
              { label: 'Deep Technology', value: 'Deep Technology' },
              { label: 'Distribution', value: 'Distribution' },
              { label: 'Drink', value: 'Drink' },
              { label: 'Drones', value: 'Drones' },
              { label: 'E-commerce', value: 'E-commerce' },
              { label: 'Education', value: 'Education' },
              { label: 'Energy', value: 'Energy' },
              { label: 'Engineering', value: 'Engineering' },
              { label: 'Entertainment', value: 'Entertainment' },
              { label: 'Fashion and accessories', value: 'Fashion and accessories' },
              { label: 'Film', value: 'Film' },
              { label: 'Financial Services', value: 'Financial Services' },
              { label: 'Fintech', value: 'Fintech' },
              { label: 'Funded primary and secondary schools', value: 'Funded primary and secondary schools' },
              { label: 'Food and Beverage', value: 'Food and Beverage' },
              { label: 'Government/Public Sector services', value: 'Government/Public Sector services' },
              { label: 'GovTech', value: 'GovTech' },
              { label: 'Haulage', value: 'Haulage' },
              { label: 'Healthcare', value: 'Healthcare' },
              { label: 'Hospitality', value: 'Hospitality' },
              { label: 'Hotels/public house/guest house', value: 'Hotels/public house/guest house' },
              { label: 'Insurance', value: 'Insurance' },
              { label: 'Insurtech', value: 'Insurtech' },
              { label: 'Insurance and reinsurance', value: 'Insurance and reinsurance' },
              { label: 'Insurance brokers', value: 'Insurance brokers' },
              { label: 'Import/export', value: 'Import/export' },
              { label: 'IT', value: 'IT' },
              { label: 'Life Sciences', value: 'Life Sciences' },
              { label: 'Logistics', value: 'Logistics' },
              { label: 'Manufacturing', value: 'Manufacturing' },
              { label: 'Marketing Services', value: 'Marketing Services' },
              { label: 'Martech', value: 'Martech' },
              { label: 'Media', value: 'Media' },
              { label: 'Medtech', value: 'Medtech' },
              { label: 'Payment Processing', value: 'Payment Processing' },
              { label: 'Print/publising', value: 'Print/publising' },
              { label: 'Professional Services', value: 'Professional Services' },
              { label: 'Property', value: 'Property' },
              { label: 'Proptech', value: 'Proptech' },
              { label: 'Recruitment Services', value: 'Recruitment Services' },
              { label: 'Recruitment Tech', value: 'Recruitment Tech' },
              { label: 'Retail', value: 'Retail' },
              { label: 'Renewable Energy', value: 'Renewable Energy' },
              { label: 'Robotics', value: 'Robotics' },
              { label: 'SaaS (Software as a Service)', value: 'SaaS (Software as a Service)' },
              { label: 'Sci & Tech Activities (excl Enviro & Quant Surveying)', value: 'Sci & Tech Activities (excl Enviro & Quant Surveying)' },
              { label: 'Security services', value: 'Security services' },
              { label: 'Social Enterprise', value: 'Social Enterprise' },
              { label: 'Sport', value: 'Sport' },
              { label: 'Sporttech', value: 'Sporttech' },
              { label: 'Technology', value: 'Technology' },
              { label: 'Trade', value: 'Trade' },
              { label: 'Transportation services', value: 'Transportation services' },
              { label: 'Transport Tech', value: 'Transport Tech' },
              { label: 'Wholesale', value: 'Wholesale' },
              { label: 'Other', value: 'Other' }
            ]
          }
        ]
      ]
    }
  ]
}
