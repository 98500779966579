<template src="./company.html"></template>

<script>
import deepcopy from 'deepcopy'
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { userRoutine } from '@/store/modules/auth/routines'
import DynamicForm from '@/components/forms/DynamicForm'
import { getCompany, patchCompanyFromJourney } from '@/api/company'
import companyInfoForm from './company.form'
import JourneyHeader from '../journeyHeader'
import { prefillFormData } from '@/utils/formHelpers'
import { SOLE_TRADER_COMPANY_NUMBER, amplitudeEvents } from '@/utils/constants'
import { sendBasicEventToAmplitude, journeyStepsButtonAmplitudeEvent, convertToDateString } from '@/utils/utils'
import steps from '@/features/journey/steps'

export default {
  name: 'JourneyCompany',
  components: {
    DynamicForm,
    JourneyHeader
  },
  mixins: [steps],
  data() {
    return {
      SOLE_TRADER_COMPANY_NUMBER,
      companyInfoForm: {},
      companyInitialData: {},
      submitPromise: null,
      amplitudeEvents,
      sendBasicEventToAmplitude,
      journeyStepsButtonAmplitudeEvent,
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    })
  },
  methods: {
    ...mapActions({
      requestUser: userRoutine.TRIGGER
    }),
    submitCompanyInfo(form) {
      sendBasicEventToAmplitude(this.$ma, journeyStepsButtonAmplitudeEvent('COMPANY_DETAILS_NEXT'))
      const { hasDifferentTradingAddress, otherFirstName = null, otherLastName = null, incorporationDate, ...formData } = form.data
      let dateSerialized = null
      if (incorporationDate) {
        dateSerialized = convertToDateString(incorporationDate)
      }
      return patchCompanyFromJourney({
        ...formData,
        incorporationDate: dateSerialized,
        yearsSinceEstablishment: parseInt(formData.yearsSinceEstablishment),
        companyId: this.companyId,
        companyNumber: formData.companyNumber || SOLE_TRADER_COMPANY_NUMBER,
        tradingAddressLine: hasDifferentTradingAddress ? formData.tradingAddressLine : formData.registeredAddressLine,
        tradingAddressPostCode: hasDifferentTradingAddress ? formData.tradingAddressPostCode : formData.registeredAddressPostcode,
        otherFirstName,
        otherLastName,
        socialEnterprise: Object.values(formData.businessModel).some(x => x === 'socialEnterprise')
      })
        .then(this.requestUser)
        .then(res => {
          this.$router.push({ name: this.nextStepName })
        })
    },
    preloadCompanyData(form) {
      return getCompany(this.companyId)
        .then(res => {
          this.companyInitialData = res.data
          return prefillFormData(form, {
            ...res.data,
            companyStructures: !res.data.companyStructures && res.data.companyNumber === SOLE_TRADER_COMPANY_NUMBER ? 'Unlimited Company (Sole Trader)' : res.data.companyStructures
          })
        })
        .catch(e => {
          console.log(`Can't preload ${form.formTitle} Form`)
        })
    },
    async init() {
      this.isLoading = true
      await this.preloadCompanyData(this.companyInfoForm)
      this.isLoading = false
    }
  },
  created() {
    this.companyInfoForm = deepcopy(companyInfoForm())
    this.init()
  }
}
</script>
